/* SecretAIChat.css */

.secret-main-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.secret-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background: #f9fafb;
  padding: 20px;
  border-right: 1px solid #e5e7eb;
}

.secret-new-conversation-button {
  width: 100%;
  padding: 10px;
  background: #2dc08d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.secret-new-conversation-button:hover {
  background: #269c73;
}

.secret-sidebar-item {
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.secret-sidebar-item:hover {
  background: #e5e7eb;
}

.secret-chat-container {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

.secret-message {
  padding: 10px 0;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.secret-message.secret-assistant {
  text-align: left;
  margin-left: 0;
  padding-left: 0;
  align-items: flex-start;
}

.secret-message-content {
  display: inline-block;
  max-width: 80%;
  padding: 20px;
  border-radius: 12px;
  font-size: 15px;
  line-height: 1.6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border: 1px solid #e5e7eb;
}

.secret-message.secret-assistant .secret-message-content {
  background: #f3faf7;
  border: 1px solid #2dc08d30;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.secret-think-box {
  font-size: 14px;
  font-style: italic;
  color: #888;
  padding: 4px 8px;
  border-left: 3px solid #aaa;
  margin: 4px 0;
  display: inline-block;
  max-width: 90%;
}

.secret-code-block-wrapper {
  position: relative;
  margin: 10px 0;
}

.secret-copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #374151;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.secret-copy-button:hover {
  background: #4b5563;
}

.secret-input-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: transparent;
  margin: 0;
  position: relative;
}

.secret-chat-input {
  width: 100%;
  padding: 12px 15px;
  padding-right: 180px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  color: #374151;
  border-radius: 8px;
  min-height: 90px;
  resize: vertical;
  box-sizing: border-box;
}

.secret-send-button {
  position: absolute;
  right: 30px;
  bottom: 30px;
  background: #2dc08d;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  z-index: 10;
}

.secret-send-button:hover {
  background: #269c73;
}

.secret-save-button {
  position: absolute;
  left: 34px;
  bottom: 25px;
  background: #374151;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.secret-save-button:hover {
  background: #4b5563;
}

.secret-stop-button {
  position: absolute;
  right: 130px;
  bottom: 30px;
  background: #ef4444;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  z-index: 10;
}

.secret-stop-button:hover {
  background: #dc2626;
}

.secret-settings-icon {
  position: absolute;
  top: 26px;
  right: 35px;
  cursor: pointer;
  color: #2dc08d;
  z-index: 10;
}

.secret-settings-dropdown {
  position: absolute;
  bottom: 100%;
  right: 20px;
  margin-bottom: 8px;
  background: #ffffff;
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  color: #374151;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
  z-index: 10;
}

.secret-settings-dropdown label {
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
}

.secret-settings-dropdown select {
  width: 100%;
  padding: 6px;
  margin-bottom: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
}
