/*---- Test Box Styling ----*/
.anml-test-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
}

/*---- Status Icon Styling ----*/
.anml-bx-error-alt {
  font-size: 50px;
  padding-bottom: 10px;
  color: #f13c20;
}

/*---- Disclaimer Text Styling ----*/
.anml-disclaimer-text {
  font-size: 18px;
  color: #f13c20;
  margin-bottom: 20px;
  padding: 10px;
}

/*---- Veriff Root Styling ----*/
#anml-veriff-root {
  margin-top: 20px;
  width: 100%;
  max-width: 350px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

/*---- Claim Button Styling ----*/
.anml-claim-button {
  margin-top: 20px;
  background-color: white;
  border: 5px solid #4056a1;
  font-size: 25px;
  border-radius: 30px;
  color: #293462;
  padding: 5px 20px;
  max-width: 200px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.anml-claim-button:hover {
  background-color: #eee;
  transition: all 0.3s ease;
}

/*---- Horizon Container Styling ----*/
.horizon-container {
  position: relative;
  height: 180px;
  width: 100%;
  margin: 0 auto 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Extra dark shadow area for visibility */
.shadow-area {
  position: absolute;
  bottom: 10px;
  width: 120px;
  height: 20px;
  background: #0000004d; /* Semi-transparent black */
  border-radius: 50%;
  filter: blur(3px);
  animation: shadow-pulse 2s ease-in-out infinite;
}

/* Hide the coin completely */
.anml-coin {
  display: none;
}

.food-item {
  position: relative;
  font-size: 100px;
  line-height: 1;
  z-index: 2;
  margin-bottom: 25px;
  animation: emoji-bob 2s ease-in-out infinite;
}

@keyframes emoji-bob {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

@keyframes shadow-pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(0.7);
    opacity: 0.3;
  }
}

/*---- Success Text Styling ----*/
.anml-success-text {
  font-size: 25px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  color: #293462; /* Match button color for consistency */
}
