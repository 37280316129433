/*---- SwapTokens Page Styling ----*/
.swap-box {
  width: 100%;
  max-width: 420px; /* Ensure it doesn't stretch too much */
  background-color: #ffffff;
  padding: 40px 30px 30px 30px; /* Increase padding at the top to make the box taller */
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin: 0 auto; /* Center the box horizontally within its container */
}

.swap-title {
  margin-bottom: 30px; /* Space between title and first input */
}

.swap-input-group {
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.swap-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.swap-input-label {
  font-size: 16px;
  margin-right: 8px;
}

.swap-token-select {
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  background-color: #f9f9f9;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
  flex-grow: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.swap-token-balance {
  font-size: 14px;
  margin-left: 10px;
  flex-grow: 1; /* Allow the balance text to take up available space */
  text-align: right; /* Align balance text to the right */
}

.swap-input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: none;
  margin-top: 10px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.swap-input-logo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  position: absolute;
  left: 10px;
}

.swap-token-input {
  background-color: #f1f0ea;
  width: 100%;
  padding: 15px 15px 15px 50px;
  font-size: 16px;
  border: none;
  text-align: right;
  border-radius: 8px;
  -moz-appearance: textfield;
}

.swap-token-input::-webkit-outer-spin-button,
.swap-token-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swap-button {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.swap-button:hover {
  background-color: #45a049;
}

.swap-button:disabled {
  /* Keep the same green color as enabled buttons */
  opacity: 0.9; /* Very slight opacity to subtly indicate disabled state */
  cursor: not-allowed;
}

.swap-button:disabled:hover {
  background-color: #4caf50; /* Keep the same color on hover */
}

.swap-max-button {
  background-color: #4caf50; /* Match the primary button color */
  color: white;
  border: none;
  border-radius: 8px; /* Match the general button shape */
  padding: 5px 10px; /* Smaller padding to keep it small */
  font-size: 12px; /* Smaller font size to fit the button */
  cursor: pointer;
  margin-left: 8px; /* Add a bit of space between the button and the balance text */
}

.swap-max-button:hover {
  background-color: #45a049; /* Darker shade on hover, consistent with the main button */
}

.swap-slippage-tolerance {
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swap-slippage-label {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 500;
  color: #333;
}

.swap-slippage-input {
  width: 60px;
  padding: 5px 8px;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.swap-price-info {
  margin-top: 0;
  padding: 0;
  background-color: #f8fbf8;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: max-height 0.4s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out, margin-top 0.3s ease-out,
    padding 0.3s ease-out;
}

.swap-price-info.visible {
  max-height: 300px;
  opacity: 1;
  transform: translateY(0);
  margin-top: 20px;
  padding: 16px;
}

.swap-price-info p {
  margin: 8px 0;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.swap-price-info p span {
  font-weight: 500;
}

.swap-note {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

/* Swap Box Styling */
.swap-box {
  width: 100%;
  max-width: 420px; /* Ensure it doesn't stretch too much */
  background-color: #ffffff;
  padding: 40px 30px 30px 30px; /* Increase padding at the top to make the box taller */
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin: 0 auto; /* Center the box horizontally within its container */
}

.swap-title {
  margin-bottom: 20px;
}

/* Input Group Styling */
.input-group {
  display: block; /* Stack label-wrapper and input-wrapper vertically */
  margin-bottom: 20px;
  position: relative;
}

/* Label Wrapper Styling */
.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

/* Input Label Styling */
.input-label {
  font-size: 16px;
  margin-right: 8px;
}

/* Token Select Styling */
.token-select {
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  border: none;
  background-color: #f9f9f9;
  outline: none;
  cursor: pointer;
  margin-left: 8px;
  flex-grow: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Add styling for token balance display */
.token-balance {
  font-size: 14px;
  margin-left: 10px;
  flex-grow: 1; /* Allow the balance text to take up available space */
  text-align: right; /* Align balance text to the right */
}

/* Input Wrapper Styling */
.input-wrapper {
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  border: none;
  margin-top: 10px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Input Logo Styling */
.input-logo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  position: absolute;
  left: 10px;
}

/* Swap Input Styling */
.swap-input {
  background-color: #f1f0ea;
  width: 100%;
  padding: 15px 15px 15px 50px;
  font-size: 16px;
  border: none;
  text-align: right;
  border-radius: 8px;
  -moz-appearance: textfield;
}

.swap-input::-webkit-outer-spin-button,
.swap-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Swap Button Styling */
.swap-button {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  font-size: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
}

.swap-button:hover {
  background-color: #45a049;
}

.max-button {
  background-color: #4caf50; /* Match the primary button color */
  color: white;
  border: none;
  border-radius: 8px; /* Match the general button shape */
  padding: 5px 10px; /* Smaller padding to keep it small */
  font-size: 12px; /* Smaller font size to fit the button */
  cursor: pointer;
  margin-left: 8px; /* Add a bit of space between the button and the balance text */
}

.max-button:hover {
  background-color: #45a049; /* Darker shade on hover, consistent with the main button */
}

/* Expandable Details Section */

.expandable-info summary {
  cursor: pointer;
  margin-top: 20px;
  outline: none;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.chevron-icon {
  margin-left: 10px;
  transition: transform 0.3s ease-in-out;
}

.expandable-info[open] .chevron-icon {
  transform: rotate(180deg);
}

.swap-details-toggle {
  background: transparent;
  color: #4caf50;
  border: none;
  margin: 10px auto 0;
  display: block;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swap-details-toggle:hover {
  color: #45a049;
}

.caret-icon {
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s ease;
  font-size: 14px;
  position: relative;
  color: #4caf50;
}

.caret-icon.open {
  transform: rotate(180deg);
}

.swap-page-box h2 {
  margin-bottom: 22px;
  text-align: center;
  font-size: 24px;
  color: #1e3a8a; /* Royal blue */
  font-weight: 600;
}
