/* General Styling */
.deflation-fund-box {
  position: relative;
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  padding: 30px 30px 30px 30px; /* Add padding to match Staking Management */
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin: 0 auto; /* Ensure the same margins as Staking Management */
}

/* Add some space between the heading and the tabs */
.deflation-fund-box h2 {
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0; /* Ensure no space above the title */
}

/* Tab Navigation Styling */
.deflation-fund-tab {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  width: 100%;
  background-color: #f1f0ea;
  border-radius: 20px;
  overflow: hidden;
}

.deflation-fund-tab button {
  background-color: #f1f0ea;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 20px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  color: #333;
  position: relative;
}

/* Rounded corners only on the outer edges */
.deflation-fund-tab button:first-child {
  border-radius: 20px 0 0 20px;
}

.deflation-fund-tab button:last-child {
  border-radius: 0 20px 20px 0;
}

.deflation-fund-tab button.active {
  background-color: #4caf50;
  color: white;
}

.deflation-fund-tab button:not(.active):hover {
  background-color: #ddd;
}

/* Chart Box Styling */
.deflation-fund-chart-box {
  border-radius: 0 0 20px 20px;
}

/* Canvas Container Styling */
.deflation-fund-canvas-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.deflation-fund-canvas-container canvas {
  width: 100%;
  max-width: 400px;
}

/* Allocation Inputs */
.deflation-fund-allocation-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.deflation-fund-allocation-input-group input {
  width: 50px;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
}

.deflation-fund-circle-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.deflation-fund-circle-button:hover {
  background-color: #45a049;
}

/* Dropdown Container */
.deflation-fund-dropdown-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.deflation-fund-dropdown-container select {
  margin-left: 10px;
  padding: 5px;
}

/* Total Indicator Styling */
.deflation-fund-total-indicator {
  border: 1px solid red;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

/* Tooltip Styling */
.deflation-fund-tooltip-container {
  position: relative;
  display: inline-block;
}

.deflation-fund-tooltip-container .deflation-fund-tooltip-trigger {
  cursor: pointer;
  font-size: 18px;
  color: #333;
}

.deflation-fund-tooltip-container .deflation-fund-tooltip-content {
  visibility: hidden;
  width: 260px;
  background-color: #4056a1;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.deflation-fund-tooltip-container:hover .deflation-fund-tooltip-content {
  visibility: visible;
  opacity: 1;
}

/* Heading and Subheading Styling */
.deflation-fund-box h1,
.deflation-fund-box h3 {
  text-align: center;
  color: #293462;
  margin-bottom: 20px;
}

.deflation-fund-box h3 {
  margin-top: 20px;
}

/* Input Container Styling */
#deflation-fund-input-container {
  margin-top: 20px;
}

#deflation-fund-total-container {
  margin-top: 10px;
  text-align: center;
}

#deflation-fund-total-container label {
  font-size: 16px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .deflation-fund-box {
    padding: 20px 15px;
  }

  .deflation-fund-canvas-container canvas {
    width: 100%;
    max-width: 300px;
  }

  .deflation-fund-claim-button {
    font-size: 20px;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Page-specific container */
.deflation-fund-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 0;
}

/* Additional page-specific styles can be added here */
