.liquidity-management-box {
    position: relative;
    width: 100%;
    max-width: 450px;
    background-color: #ffffff;
    padding: 40px 30px 30px 30px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin: 0 auto;
  }
  
  .liquidity-management-close-button {
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    background-color: #4CAF50;
    color: white;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    line-height: 40px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-size: 25px;
    z-index: 5;
  }
  
  .liquidity-management-close-button:hover {
    background-color: #45a049;
  }
  
  /* Tab Navigation Styling */
  .liquidity-management-tab {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .liquidity-management-tab button {
    margin-top: 15px;
    background-color: #F1F0EA;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 20px;
    transition: background-color 0.3s ease;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    border-radius: 0;
    color: #333;
  }
  
  .liquidity-management-tab button:first-child {
    border-radius: 10px 0 0 10px;
  }
  
  .liquidity-management-tab button:last-child {
    border-radius: 0 10px 10px 0;
  }
  
  .liquidity-management-tab button.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .liquidity-management-tab button:not(.active):hover {
    background-color: #ccc;
  }
  
  .liquidity-management-tabcontent {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: -20px;
  }
  
  /* Input Group Styling */
  .liquidity-management-input-group {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  /* Label Wrapper Styling */
  .liquidity-management-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  /* Input Label Styling */
  .liquidity-management-input-label {
    font-size: 16px;
    color: #333;
    margin-right: 8px;
  }
  
  /* Input Wrapper Styling */
  .liquidity-management-input-wrapper {
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #ccc;
    position: relative;
    width: 100%;
  }
  
  /* Input Logo Styling */
  .liquidity-management-input-logo {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    position: absolute;
    left: 10px;
  }
  
  /* Input Styling */
  .liquidity-management-input {
    background-color: #F1F0EA;
    width: 100%;
    padding: 15px 15px 15px 50px;
    font-size: 16px;
    border: none;
    text-align: right;
    border-radius: 8px;
    -moz-appearance: textfield;
  }
  
  .liquidity-management-input::-webkit-outer-spin-button,
  .liquidity-management-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Button Styling */
  .liquidity-management-button {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    font-size: 18px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .liquidity-management-button:hover {
    background-color: #45a049;
  }
  
  /* Balance Display Styling */
  .balance-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
  }
  
  .max-button {
    margin-left: 10px;
    padding: 5px 10px;
    font-size: 14px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .max-button:hover {
    background-color: #45a049;
  }
  
  .toggle-switch-container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-right: 10px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  .switch input:checked + .slider-toggle {
    background-color: #4CAF50;
  }
  
  .switch input:checked + .slider-toggle:before {
    transform: translateX(26px);
  }
  
  .toggle-label {
    font-size: 16px;
    vertical-align: middle;
    color: #333;
  }
  
  /* Unbonding Requests Section */
  .unbonding-requests {
    margin-top: 20px;
  }
  
  .unbonding-requests h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .unbonding-requests ul {
    list-style: none;
    padding: 0;
  }
  
  .unbonding-requests li {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }
  