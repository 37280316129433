/*---- Claim Button Styling ----*/
.lp-claim-all-button {
  border: none;
  margin-top: 15px;
  margin-bottom: 25px;
  background-color: #4caf50;
  font-size: 20px;
  border-radius: 30px;
  color: white;
  padding: 5px 20px;
  max-width: 175px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.lp-claim-all-button:hover {
  background-color: #45a049;
  transition: all 0.3s ease;
}

.lp-total-rewards-text {
  margin-bottom: 50px;
  font-size: 25px;
  color: #293462;
  padding: 20px;
  text-align: center;
}
