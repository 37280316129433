/* General Styling */
.allocation-fund-box {
  position: relative;
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin: 0 auto;
}

.allocation-fund-box h2 {
  margin-bottom: 15px;
}

/* Tab Navigation Styling */
.allocation-fund-tab {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  width: 100%;
  background-color: #f1f0ea;
  border-radius: 20px;
  overflow: hidden;
}

.allocation-fund-tab button {
  background-color: #f1f0ea;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 20px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  flex-grow: 1;
  text-align: center;
  color: #333;
  position: relative;
}

/* Rounded corners only on the outer edges */
.allocation-fund-tab button:first-child {
  border-radius: 20px 0 0 20px;
}

.allocation-fund-tab button:last-child {
  border-radius: 0 20px 20px 0;
}

.allocation-fund-tab button.active {
  background-color: #4caf50;
  color: white;
}

.allocation-fund-tab button:not(.active):hover {
  background-color: #ddd;
}

/* Chart Box Styling */
.allocation-fund-chart-box {
  border-radius: 0 0 20px 20px;
}

/* Canvas Container Styling */
.allocation-fund-canvas-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.allocation-fund-canvas-container canvas {
  width: 100%;
  max-width: 400px;
}

/* Allocation Inputs */
.allocation-fund-input-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.allocation-fund-input-group input {
  width: 50px;
  margin-right: 10px;
  padding: 5px;
  text-align: right;
}

.allocation-fund-circle-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.allocation-fund-circle-button:hover {
  background-color: #45a049;
}

/* Dropdown Container */
.allocation-fund-dropdown-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.allocation-fund-dropdown-container select {
  margin-left: 10px;
  padding: 5px;
}

/* Input Container Styling */
.allocation-fund-input-container {
  margin-top: 20px;
}

/* Claim Button */
.allocation-fund-claim-button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  transition: background-color 0.3s;
}

.allocation-fund-claim-button:hover {
  background-color: #45a049;
}

/* Message Styling */
.allocation-fund-error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

.allocation-fund-success-message {
  background-color: #e8f5e9;
  color: #388e3c;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

/* Disable button styling */
.allocation-fund-claim-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .allocation-fund-box {
    padding: 20px 15px;
  }

  .allocation-fund-canvas-container canvas {
    width: 100%;
    max-width: 300px;
  }
}
