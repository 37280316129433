/* Full-screen grey background */
.status-modal-overlay {
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent grey background */
  z-index: 999; /* Ensure it appears behind the modal content */
}

/* Modal Content centered in parent */
.status-modal-content {
  position: absolute; /* Absolute positioning to stay centered in parent */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the modal horizontally and vertically */
  background: url("../images/loading.gif") no-repeat center center;
  background-size: cover; /* Ensure the background fits within the modal */
  width: 600px;
  height: 600px;
  border-radius: 50%; /* Make the modal content circular */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal content stays on top */
}

.status-modal-checkmark-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-modal-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.status-modal-checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #7ac142; /* Green stroke for the circle */
  fill: none; /* Ensure there's no white fill */
  margin: 10% auto;
  /* Removed the box-shadow to avoid the white fill effect */
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.status-modal-checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: #7ac142; /* Green checkmark */
  stroke-width: 4; /* Ensure the stroke is thick enough */
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: none; /* Remove the box-shadow fill */
  }
}

/* Error Crossmark */
.status-modal-error-crossmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: inline-block;
  border: 4px solid #dc3545; /* Red color for error */
  position: relative;
}

.status-modal-error-crossmark:before,
.status-modal-error-crossmark:after {
  content: "";
  width: 40px;
  height: 5px;
  background-color: #dc3545;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  transform-origin: center;
}

.status-modal-error-crossmark:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@keyframes draw-error {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 40px;
    height: 5px;
  }
}

/* Prevent page scrolling when modal is open */
body.modal-open {
  overflow: hidden;
}
