/*---- Analytics Page Styling ----*/
.analytics-page {
  width: 96%;
  margin: auto;
  background-color: rgba(209, 209, 209, 0.2);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*---- Analytics Price Section Styling ----*/
.analytics-price-section {
  width: 96%;
  margin: 25px auto;
  background-color: rgba(209, 209, 209, 0.2);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*---- Analytics Info Styling ----*/
.analytics-info {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

/*---- Analytics Info Item Styling ----*/
.analytics-info-item {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.analytics-info-label {
  font-weight: bold;
  margin-right: 10px;
  min-width: 150px;
}

.analytics-info-value {
  font-family: monospace;
}

/*---- Analytics Section Styling ----*/
.analytics-section {
  margin-bottom: 30px;
}

.analytics-section-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

/*---- Analytics Table Styling ----*/
.analytics-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  overflow: hidden;
}

.analytics-table th,
.analytics-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.analytics-table th {
  background-color: #f8f8f8;
  font-weight: bold;
  color: #333;
}

.analytics-table tr:hover {
  background-color: #f1f1f1;
}

/*---- Analytics Loading State Styling ----*/
.analytics-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.analytics-loading-message,
.analytics-error-message {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.analytics-error-message {
  color: #e74c3c;
}

/*---- Analytics Chart Container Styling ----*/
.analytics-chart-container {
  height: 300px;
  margin-top: 15px;
  margin-bottom: 20px; /* Add bottom margin to the chart */
  background-color: rgba(76, 175, 80, 0.05); /* Very light green background */
  border-radius: 10px;
  padding: 15px;
  padding-top: 30px; /* Add more padding on top for the title */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(76, 175, 80, 0.3); /* Subtle green border */
  position: relative;
  overflow: hidden;
}

/* Add a subtle green glow on hover */
.analytics-chart-container:hover {
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.2);
}

/* Add section title inside chart area */
.analytics-chart-container::before {
  content: "ERTH Price";
  position: absolute;
  top: 8px;
  left: 15px;
  font-size: 12px;
  color: #4caf50;
  font-weight: 600;
  z-index: 1; /* Ensure it's above the chart but not above other UI elements */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 2px 8px;
  border-radius: 4px;
}

/* ANML Chart specific styling */
.analytics-chart-container.anml-chart::before {
  content: "ANML Price";
  color: #1e3a8a; /* Royal blue for ANML */
}

.analytics-chart-container.anml-chart {
  border-color: rgba(30, 58, 138, 0.3); /* Blue border for ANML chart */
}

.analytics-chart-container.anml-chart:hover {
  box-shadow: 0 0 15px rgba(30, 58, 138, 0.2); /* Blue glow for ANML chart */
}

/*---- Responsive Design ----*/
@media (max-width: 768px) {
  .analytics-page {
    width: 90%;
    padding: 15px;
  }

  .analytics-info-label {
    min-width: 120px;
  }

  .analytics-table th,
  .analytics-table td {
    padding: 8px 10px;
    font-size: 0.9em;
  }
}

/* Analytics Page Styling - Matches StakeErth Styling */

/* Add styles to ensure the page is scrollable */
body,
html {
  min-height: 100%;
  height: 100%;
  background-color: #f5f5f5; /* Light gray background for the page */
}

#root {
  min-height: 100%;
  overflow-y: auto; /* Enable scrolling on the main container */
  background-color: #f5f5f5; /* Match body background */
}

/* Main Analytics Container */
.analytics-page-box {
  width: 100%;
  max-width: 450px;
  background-color: #ffffff;
  padding: 40px 30px 50px 30px;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto 60px; /* Reasonable top margin */
  position: relative;
  z-index: 1;
}

/* Heading Styling */
.analytics-page-box h2 {
  margin-bottom: 22px;
  text-align: center;
  font-size: 24px;
  color: #1e3a8a; /* Royal blue */
  font-weight: 600;
}

/* Tab Navigation Styling */
.analytics-page-tab {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
  background-color: #f1f0ea; /* Light beige */
  border-radius: 10px;
  overflow: hidden;
}

.analytics-page-tab button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 0;
  transition: all 0.3s ease;
  font-size: 14px;
  flex-grow: 1;
  text-align: center;
  color: #1e3a8a; /* Royal blue */
  font-weight: 500;
  border-radius: 0;
}

.analytics-page-tab button:first-child {
  border-radius: 10px 0 0 10px;
}

.analytics-page-tab button:last-child {
  border-radius: 0 10px 10px 0;
}

.analytics-page-tab button.active {
  background-color: #4caf50;
  color: white;
  transform: none;
  box-shadow: none;
}

.analytics-page-tab button:not(.active):hover {
  background-color: #ccc;
  color: #1e3a8a; /* Keep royal blue on hover */
}

/* Tab Content Styling with fixed height and scrolling */
.analytics-page-tabcontent {
  width: 100%;
  animation: fadeIn 0.3s ease-in-out;
  display: none;
  max-height: 400px; /* Reduce height from 500px to 400px */
  overflow-y: auto; /* Add scrolling to tab content */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

/* Custom scrollbar styling for tab content */
.analytics-page-tabcontent::-webkit-scrollbar {
  width: 6px;
}

.analytics-page-tabcontent::-webkit-scrollbar-track {
  background: rgba(76, 175, 80, 0.05);
  border-radius: 10px;
}

.analytics-page-tabcontent::-webkit-scrollbar-thumb {
  background-color: rgba(76, 175, 80, 0.3);
  border-radius: 10px;
}

.analytics-page-tabcontent.active {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Section Styling */
.analytics-section {
  margin-bottom: 20px;
}

.analytics-section-title {
  font-size: 18px;
  color: #1e3a8a; /* Royal blue */
  margin-bottom: 15px;
  text-align: center;
  font-weight: 600;
}

/* Info Display Section */
.analytics-info-display {
  background-color: transparent;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: none;
}

/* Info Row Styling */
.analytics-info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.analytics-info-row:last-child {
  border-bottom: none;
}

.analytics-info-label {
  color: #1e3a8a; /* Royal blue */
  font-size: 14px;
}

.analytics-info-value {
  font-size: 15px;
  color: #1e3a8a; /* Royal blue */
  font-weight: 600;
  text-align: right;
}

/* Divider */
.analytics-divider {
  height: 1px;
  background-color: #e0e0e0; /* Lighter gray divider */
  width: 100%;
  margin: 24px 0;
}

/* Analytics Table Styling */
.analytics-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: #f1f0ea; /* Light beige */
  border-radius: 10px;
  overflow: hidden;
}

.analytics-table th,
.analytics-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.analytics-table th {
  background-color: #f1f0ea; /* Light beige */
  font-weight: 600;
  color: #1e3a8a; /* Royal blue */
}

.analytics-table tr:hover {
  background-color: rgba(76, 175, 80, 0.05);
}

/* Loading and Error States */
.analytics-loading-message,
.analytics-error-message {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  color: #1e3a8a; /* Royal blue */
  font-style: italic;
}

.analytics-error-message {
  color: #e74c3c; /* Red */
}

/* Note text */
.analytics-note {
  text-align: center;
  color: #1e3a8a; /* Royal blue */
  font-size: 14px;
  margin: 10px 0;
  font-style: italic;
}

/* Responsive Design */
@media (max-width: 768px) {
  .analytics-page-box {
    width: 90%;
    padding: 30px 20px;
  }

  .analytics-info-label {
    min-width: 120px;
  }

  .analytics-table th,
  .analytics-table td {
    padding: 8px 10px;
    font-size: 0.9em;
  }
}

/* Time Range Selector */
.analytics-time-selector {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  padding: 0 5px;
}

.analytics-time-selector button {
  background-color: transparent;
  border: 1px solid rgba(76, 175, 80, 0.3);
  color: #1e3a8a;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 10px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.analytics-time-selector button:hover {
  background-color: rgba(76, 175, 80, 0.05);
  border-color: rgba(76, 175, 80, 0.5);
}

.analytics-time-selector button.active {
  background-color: rgba(76, 175, 80, 0.15);
  border-color: #4caf50;
  color: #4caf50;
  font-weight: 600;
}

/* Analytics Summary Container for TVL */
.analytics-summary-container {
  background-color: #f8fbf8; /* Light green background */
  border-radius: 12px;
  padding: 18px;
  margin-bottom: 20px;
  border: 1px solid #4caf50; /* Green outline */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.analytics-tvl-label {
  font-size: 16px;
  font-weight: 600;
  color: #1e3a8a; /* Royal blue */
}

.analytics-tvl-value {
  font-size: 18px;
  font-weight: 700;
  color: #4caf50; /* Green for positive value emphasis */
}

/* Pool Section Styling */
.pool-section {
  background-color: #f8fbf8; /* Light green background */
  border-radius: 12px;
  padding: 18px;
  border: 1px solid #4caf50; /* Green outline */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.pool-section .analytics-section-title {
  color: #1e3a8a;
  margin-bottom: 15px;
}

/* Override table styling in pool section */
.pool-section .analytics-table {
  background-color: transparent; /* Remove beige background */
}

.pool-section .analytics-table th {
  background-color: transparent; /* Remove beige background from headers */
  color: #1e3a8a; /* Keep royal blue color */
}

.pool-section .analytics-table tr:hover {
  background-color: rgba(76, 175, 80, 0.1); /* Lighter green hover effect */
}
